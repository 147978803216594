import { IAuthResponseOptions, useMfaToken } from 'api/goodtrust/auth.util'
import { postContactBind } from 'api/goodtrust/contact'
import { SAuthContainer } from 'components/auth/AuthContainer'
import { Login } from 'components/auth/Login'
import { Block } from 'components/Block'
import { TopNavigationLandingPage } from 'components/header/topNavigationLandingPage/TopNavigationLandingPage'
import { Layout } from 'components/layout/Layout'
import { PageTitle } from 'components/PageTitle'
import { toastError } from 'components/Toast'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { encodeQuery } from 'utils/fetcher'
import { getIntlProps } from 'utils/i18n/getIntlProps'
import { useModal } from 'components/modal/Modal'
import { EmailVerificationModal } from 'components/modal/emailVerificationModal/EmailVerificationModal'
import { emailModalConfig } from 'utils/email'

export default function Page() {
  const { t } = useTranslation('auth')
  const { isLogged, mutateAuth } = useAuth()
  const router = useRouter()
  const mfaToken = useMfaToken()
  const contactUuid = router.query.contact as string | undefined
  const next = router.query.next as string | undefined

  const [emailVerificationModal, showEmailVerificationModal] = useModal(
    ({ close, isOpen }) => (isOpen ? <EmailVerificationModal closeModal={close} /> : null),
    emailModalConfig
  )

  const authOpts: IAuthResponseOptions = {
    contact: contactUuid,
    async onMfa(token) {
      await mfaToken.mutate(token)
      return router.push(encodeQuery('/login/mfa', { next, contact: contactUuid }))
    },
    onLoginSuccess: () => mutateAuth(),
    onVerificationRequired: showEmailVerificationModal,
  }

  useEffect(() => {
    if (isLogged === true) {
      if (contactUuid) {
        postContactBind({ uuid: contactUuid }).catch(() => toastError('failed_to_bind_contact'))
      }

      setTimeout(() => void Router.replace(next ?? '/me/dashboard'), 10)
    }
  }, [isLogged, contactUuid, next])

  return (
    <Layout header={<TopNavigationLandingPage variant="general-info" />}>
      <PageTitle title={t('auth.login.meta_title')} />
      <Block>
        <SAuthContainer>
          {emailVerificationModal}
          <Login
            title={t('auth.login.title')}
            registerHref={encodeQuery('/signup', { contact: contactUuid, next })}
            resetPasswordHref="/reset-password"
            authOpts={authOpts}
          />
        </SAuthContainer>
      </Block>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  return { props: { ...(await getIntlProps(ctx, 'auth')) } }
}
